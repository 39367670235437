import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Space, Table } from "@pankod/refine-antd";
import { useApiUrl, useCreate, useCustom } from '@pankod/refine-core';


export const AssociatedOwners = ({ ownerId }) => {

    const apiUrl = useApiUrl();

    const { data, refetch } = useCustom({
        config: {
        },
        method: 'get',
        url: `${ apiUrl }/owners/associatedOwners`,

    });

    const { mutate } = useCreate();


    const associatedOwners = data?.data;


    const deasociateOwner = (ownerId) => {

        mutate({
            resource: 'owners/deasociateOwner',
            successNotification: { description: 'Usuario desasociado', message: `Se desasoció al usuario de su cuenta`, type: 'success'},
            values: { ownerId }
        }, {
            onSuccess: () => refetch()
        });

    }


    const onFinish = (values) => {

        mutate({
            resource: 'owners/associatedOwners',
            successNotification: { description: 'Usuario agregado', message: `Se asoció al usuario ${ values.username } a su cuenta`, type: 'success'},
            values
        }, {
            onSuccess: () => refetch()
        });


    };

    const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 12,
            offset: 8,
        },
        sm: {
            span: 12,
            offset: 8,
            },
        },
    };


    const associatedOwnersCols = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'associated_id',
        },
        {
            title: 'Usuario',
            dataIndex: 'email'
        },
        {
            title: 'Nombre',
            dataIndex: 'name'
        },
        {
            title: 'Apellidos',
            dataIndex: 'lastname'
        },
        {
            title: 'Desasociar',
            dataIndex: 'id',
            render: (id, record) => (
                <Space>
                    <Popconfirm
                        key="delete"
                        okText="S&iacute;, desasociar."
                        cancelText="Cancelar"
                        okType="danger"
                        title="Seguro que desea desasociar a este usuario?"
                        onConfirm={() => {
                            deasociateOwner(record.id)
                        }}
                    >

                        <Button
                            danger
                            icon={<DeleteOutlined />}
                        />

                    </Popconfirm>

                </Space>
            )
        }

    ]


    return (

        <>

            <Table columns={ associatedOwnersCols } dataSource={ associatedOwners } rowKey={ (record) => `associated_user_${ record.id }` } size="small"/>

            <br/>&nbsp;<br/>


            <Form
                labelCol={{ span: 14, offset: 3 }}
                layout="vertical"
                onFinish={ onFinish }
                wrapperCol={{ span: 10, offset: 4 }}
            >

                <Form.Item
                    label="Ingrese un nombre de usuario (email) a asociar (deber&aacute; haberse registrado en la plataforma con anterioridad):"
                    name="username"
                    rules={
                        [
                            { required: true }
                        ]
                    }
                >

                    <Input placeholder="mail@example.com" />

                </Form.Item>

                <Form.Item { ...tailFormItemLayout }>
                    <Button type="primary" htmlType="submit">
                        Agregar
                    </Button>
                </Form.Item>

            </Form>

        </>

    )

}
