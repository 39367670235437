import { useEffect, useState } from 'react';

import { QuestionCircleOutlined } from "@ant-design/icons";
import { useApiUrl, useCreate, useNotification } from "@pankod/refine-core";

import { AntdLayout, Checkbox, Col, Create, Form, getValueFromEvent, Image, Input, InputNumber, Modal, Row, Select, Tooltip, Typography, Upload, useForm, useSelect } from "@pankod/refine-antd";

import { getUserIdentity } from '../app/authProvider';

import AddToCatalogs from './addToCatalogs';

import './styles.css';

const { Title, Text } = Typography;




export const SpacesCreate = () => {

    const { open } = useNotification();

    const [ id_adxpace, setIdAdxpace ] = useState('');

    const [ listing_type, setListingType ] = useState('');

    const { form, formProps, getFieldsError, handleSubmit, saveButtonProps } = useForm();

    const [ supercategory, setSupercategory ] = useState('');

    const [ category, setCategory ] = useState('');

    const [ subcategory, setSubcategory ] = useState('');

    const [ subcategory1, setSubcategory1 ] = useState('');


    const [ currentEditingCatalog, setCurrentEditingCatalog ] = useState(null);

    const [ currentEditingResource, setCurrentEditingResorce ] = useState(null);

    const [ isModalVisible, setIsModalVisible ] = useState(false);

    const hideModal = (shouldRefetch, value) => {

        setIsModalVisible(false);

        if(shouldRefetch && currentEditingResource){

            currentEditingResource.queryResult.refetch();
            setCurrentEditingResorce(null);
            form.setFieldsValue( { [ value.catalogName.toLowerCase() ]: value.catalogValue } );

        }

    };

    const displayModal = () => { setIsModalVisible(true) };

    const notifyFileUploaded = (e) => {

        if(e.event?.percent === 100){

            open?.({
                type: "success",
                message: `El archivo con su foto ${ e.file?.name } está listo para usarse.`,
                description: "Archivo entregado",
                key: e.event?.timestamp,
            });

        }

    }


    const { queryResult } = useCreate();

    const apiUrl = useApiUrl();


    const orientaciones = useSelect({
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        optionLabel: 'option_value',
        optionValue: 'option_value',
        showSearch: false,
        resource: 'mySpaces/catalogs/orientacion'
    });


    const vistas = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/vista'
    });

    const estructura = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/estructura'
    });


    const nivel_socioeconomico_zona = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/nivel_socioeconomico_zona'
    });


    const nivel_socioeconomico_audiencia = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/nivel_socioeconomico_audiencia'
    });


    const tipo_establecimiento = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/tipo_establecimiento'
    });


    const trafico_vehicular = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/trafico_vehicular'
    });


    const vialidad = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/vialidad'
    });


    const visibilidad = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/visibilidad'
    });


    const calificacion = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/calificacion'
    });


    const supercategoria = useSelect({
        optionLabel: 'termName',
        optionValue: 'termId',
        resource: 'mySpaces/categories/0'
    });


    const categoria = useSelect({
        defaultValue: null,
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: supercategory !== ''
        },
        resource: `mySpaces/categories/${ supercategory }`
    });


    const subcategoria = useSelect({
        defaultValue: null,
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: category !== ''
        },
        resource: `mySpaces/categories/${ category }`
    });


    const subcategoria1 = useSelect({
        defaultValue: null,
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: subcategory !== ''
        },
        resource: `mySpaces/categories/${ subcategory }`
    });


    const subcategoria2 = useSelect({
        defaultValue: null,
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: subcategory !== ''
        },
        resource: `mySpaces/categories/${ subcategory1 }`
    });


    const clearCategory = () => {

        form.setFieldsValue({
            tipo: null,
            formato_publicitario: null,
            subcategoria_1: null,
            subcategoria_2: null
        });

        setCategory('');
        setSubcategory('');
        setSubcategory1('');

    }

    const clearSubcategory = () => {

        form.setFieldsValue({
            formato_publicitario: null,
            subcategoria_1: null,
            subcategoria_2: null
        });

        setSubcategory('');
        setSubcategory1('');

    }

    const clearSubcategory1 = () => {

        form.setFieldsValue({
            subcategoria_1: null,
            subcategoria_2: null
        });

        setSubcategory1('');

    }


    const clearSubcategory2 = () => {

        form.setFieldsValue({
            subcategoria_2: null
        });

    }


	useEffect(() => {

        if(!id_adxpace)

        getUserIdentity()
    	.then(r => {

    		if(r.id_adxpace){

    			setIdAdxpace(r.id_adxpace);

    		}

    	});

    });


    const onSubmit = (e) => {

        console.log(e);
        const validationErrors = form.getFieldsError();
        console.log(validationErrors);

    }
    


    return (

        <>

            {

                id_adxpace ?

                <Create saveButtonProps={ saveButtonProps } title="Alta de espacios">

                <Row>
                            <Col offset={ 6 } span={ 12 }>
                                <Text>
                                    - Utiliza letras min&uacute;sculas en las descripciones (usa may&uacute;sculas solamente para nombres propios y primer letra de cada sentencia). <br/>
                                </Text><br/>
                                <Text>
                                    - Recuerda que mientras m&aacute;s fotos e informaci&oacute;n de tu espacio proporciones, &eacute;ste resultar&aacute; m&aacute;s atractivo para tus clientes.
                                </Text>
                            </Col>
                        </Row>

                    <Form {  ...formProps }
                        labelAlign="left"
                        labelWrap
                        onFinishFailed={(e) => {
                            open?.({
                                type: "error",
                                message: `Hay campos que son necesarios para poder crear su espacio. Por favor revise su formulario y sus imágenes.`,
                                description: "No se pudo guardar su espacio.",
                                key: e.event?.timestamp,
                            });}}
                        onSubmit={ onSubmit }
                        scrollToFirstError={true}
                    >

                        <br/>

                        <AntdLayout>

                <AntdLayout.Sider
                    breakpoint="xs"
                    className="inner-sider"
                    id="_form_layout"
                    width="400"
                >


                    <Form.Item>
                        <Form.Item
                            name="imagenes"
                            valuePropName="fileList"
                            getValueFromEvent={ getValueFromEvent }
                            rules={ [
                                { required: true },
                                {
                                    validator: async(_, value) => {
                                        
                                        if(!value || value.length === 0){
                                            return Promise.reject(new Error("'Agregue al menos una imagen para su espacio.'"))
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ] }
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${ apiUrl }/mySpaces/upload`}
                                headers={{ 'Authorization': localStorage.getItem('authToken') }}
                                listType="picture"
                                maxCount={ 5 }
                                multiple
                                onChange={ notifyFileUploaded }
                            >
                                <Title level={ 5 }>
                                    Arrastre los archivos de sus fotos aqu&iacute;.
                                </Title>
                                <Text>
                                    <br/>Usted puede agregar hasta 5 archivos para su espacio. <br/>
                                </Text>
                                <Text>
                                    <br/>Siempre recomendamos subir 3 im&aacute;genes por espacio.<br/>
                                </Text>
                                <Text>
                                    <br/>Recuerde que las im&aacute;genes e informaci&oacute;n del espacio son responsabilidad del propietario.
                                </Text>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                </AntdLayout.Sider>

                <AntdLayout.Content
                    className="inner-content"
                >

                    <Tooltip title={ `Este es el código con el que el propietario identifica su espacio.` } placement="right">
                    <Form.Item
                        id="_codigo_espacio"
                        label="C&oacute;digo de tu espacio"
                        name="codigo"
                        rules={ [ { required: true } ] }
                        wrapperCol={{ span: 7 }}
                    >

                            <Input />

                    </Form.Item>
                    </Tooltip>

                    <br/>

                    <Title level={ 5 } className="add-item-required">Domicilio del espacio</Title>

                    <Form.Item style={{ marginBottom: 0 }}>

                        <Form.Item

                            name="calle"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
                        >
                            <Input placeholder="Calle" />
                        </Form.Item>

                        <Form.Item

                            name="numero"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="N&uacute;mero o S/N" />
                        </Form.Item>

                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}>

                        <Form.Item

                            name="colonia"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Input placeholder="Colonia" />
                        </Form.Item>

                        <Form.Item

                            name="municipio"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Input placeholder="Municipio o alcald&iacute;a" />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item

                            name="estado"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Input placeholder="Estado" />
                        </Form.Item>

                        <Form.Item

                            name="codigo_postal"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="C&oacute;digo postal" />
                        </Form.Item>

                    </Form.Item>

                    <Title level={ 5 }>Categor&iacute;a</Title>
                    <Text level={ 6 }>En caso de no encontrar la categor&iacute;a que corresponde a su espacio, favor de ponerse en contacto con ADXpace.<br/></Text><br/>

                    <Form.Item>

                        <Form.Item
                            label="Supercategor&iacute;a"
                            name="lugar_sitio_publicitario"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...supercategoria.selectProps } onChange={ val => { setSupercategory(val); clearCategory(); } } placeholder="Seleccione una opción" />
                        </Form.Item>

                        {

                            supercategory !== '' && categoria.selectProps.options.length > 0 &&

                                <Form.Item
                                label="Categor&iacute;a"
                                name="tipo"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...categoria.selectProps }  onChange={ val => { setCategory(val); clearSubcategory(); } } placeholder="Seleccione una opción" />
                            </Form.Item>

                        }

                        {

                            category !== '' && subcategoria.selectProps.options.length > 0 &&

                            <Form.Item
                                label="Subcategor&iacute;a"
                                name="formato_publicitario"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...subcategoria.selectProps } onChange={ val => { setSubcategory(val); clearSubcategory1(); }} placeholder="Seleccione una opción"  />
                            </Form.Item>

                        }

                        {

                            subcategory !== '' && subcategoria1.selectProps.options.length > 0 &&

                            <Form.Item
                                label="Subcategor&iacute;a A"
                                name="subcategoria_1"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...subcategoria1.selectProps } onChange={ val => { setSubcategory1(val); clearSubcategory2(); } } placeholder="Seleccione una opción" />
                            </Form.Item>

                        }


                        {

                            subcategory1 !== '' && subcategoria2.selectProps.options.length > 0 &&

                            <Form.Item
                                label="Subcategor&iacute;a B"
                                name="subcategoria_2"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...subcategoria2.selectProps } placeholder="Seleccione una opción" />
                            </Form.Item>

                        }



                    </Form.Item>


                    <Title level={ 5 } className="add-item-required">Geolocalizaci&oacute;n (M&iacute;nimo 5 decimales).</Title>

                    <Form.Item>

                        <Form.Item
                            name="latitud"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="Latitud" />
                        </Form.Item>

                        <Form.Item

                            name="longitud"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="Longitud" />
                        </Form.Item>

                    </Form.Item>

                    <Tooltip title={ `El precio debe incluir el 15% de comisión para ADXpace.` } placement="top">
                        <Form.Item
                            label="Precio mensual (MN)"
                            name="precio"
                            rules={ [ { required: true } ] }
                            
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '4px' }}
                        >

                                <InputNumber 
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '200px' }}
                                />

                        </Form.Item>
                    </Tooltip>


                    <Form.Item>



                        <Form.Item
                            label="Per&iacute;odo m&iacute;nimo de contrataci&oacute;n (en d&iacute;as):"
                            name="periodo_de_contratacion"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '4px' }}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Per&iacute;odo de pago (en d&iacute;as):"
                            name="periodo_de_pago"
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Ofrece descuento por volumen:"
                            name="descuento_volumen_tiempo"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>

                    </Form.Item>




                    <Title level={ 4 }>Caracter&iacute;sticas</Title>
                    <Text level={ 5 }>(Recuerda que mientras m&aacute;s informaci&oacute;n proporciones, tu espacio ser&aacute; m&aacute;s atractivo). <br/>&nbsp; <br/></Text>


                    <Form.Item>

                        <Form.Item
                            label="Orientaci&oacute;n"
                            name="orientacion"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...orientaciones.selectProps } onChange={ (v) => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Orientacion');
                                    setCurrentEditingResorce(orientaciones);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Vista"
                            name="vista"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...vistas.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Vista');
                                    setCurrentEditingResorce(vistas);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>


                    <Title level={ 5 }>Dimensiones</Title>

                    <Form.Item>
                        <Form.Item
                            label="Base (en metros):"
                            name="base"
                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber  />
                        </Form.Item>

                        <Form.Item
                            label="Altura (en metros):"
                            name="altura"
                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber placeholder="" />
                        </Form.Item>

                        <Form.Item
                            label="Estructura:"
                            name="estructura"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <Select { ...estructura.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Estructura');
                                    setCurrentEditingResorce(estructura);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>

                    <Form.Item>
                        <Form.Item
                            label="Tama&ntilde;o de pantalla (pulgadas):"
                            name="tamano_pantalla"

                            style={{ display: 'inline-block', width: 'calc(49% - 8px)' }}
                        >
                            <InputNumber placeholder="" />
                        </Form.Item>

                        <Form.Item
                            label="Pixeles:"
                            name="pixeles"
                            style={{ display: 'inline-block', width: 'calc(49% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                    </Form.Item>





                    <Form.Item>

                        <Form.Item
                            label="Nivel socioecon&oacute;mico zona:"
                            name="nivel_socioeconomico_zona"
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '4px' }}
                        >
                            <Select { ...nivel_socioeconomico_zona.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Nivel socioeconomico zona');
                                    setCurrentEditingResorce(nivel_socioeconomico_zona);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Nivel socioecon&oacute;mico audiencia:"
                            name="nivel_socioeconomico_audiencia"

                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        >
                            <Select { ...nivel_socioeconomico_audiencia.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Nivel socioeconomico audiencia');
                                    setCurrentEditingResorce(nivel_socioeconomico_audiencia);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Tipo de establecimiento:"
                            name="tipo_de_establecimiento"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)', marginRight: '4px' }}
                        >
                            <Select { ...tipo_establecimiento.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Tipo de establecimiento');
                                    setCurrentEditingResorce(tipo_establecimiento);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="N&uacute;mero de muebles:"
                            name="no_de_muebles"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="N&uacute;mero de caras:"
                            name="no_de_caras"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Tr&aacute;fico vehicular:"
                            name="trafico_vehicular"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)', marginRight: '4px' }}
                        >
                            <Select { ...trafico_vehicular.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Trafico vehicular');
                                    setCurrentEditingResorce(trafico_vehicular);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Audiencia diaria:"
                            name="audiencia_diaria"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Afluencia anual:"
                            name="audiencia_anual"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Clave IBOPE:"
                            name="clave_ibope"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="IBOPE GLP:"
                            name="ibope_glp"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="IBOPE SVS:"
                            name="ibope_svs"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Vialidad:"
                            name="vialidad"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...vialidad.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Vialidad');
                                    setCurrentEditingResorce(vialidad);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Visibilidad:"
                            name="visibilidad"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...visibilidad.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Visibilidad');
                                    setCurrentEditingResorce(visibilidad);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Calificacion:"
                            name="calificacion"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...calificacion.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Calificacion');
                                    setCurrentEditingResorce(calificacion);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item
                        label="Observaciones"
                        name="observaciones"

                        style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                    >
                        <Input.TextArea rows={ 4 } />
                    </Form.Item>


                </AntdLayout.Content>

            </AntdLayout>

                    </Form>

                </Create>

                :

    			<div className="notice">
                    <Title level={ 4 }>Para poder administrar sus espacios es necesario que ingrese su R. F. C. en la secci&oacute;n Perfil de usuario.</Title>
                </div>

            }

            <Modal
                bodyStyle={{ padding: '0' }}
                footer={ null }
                onCancel={ () => hideModal(false) }
                title="Agregar valor a cat&aacute;logo"
                visible={ isModalVisible }
            >
                <AddToCatalogs catalogName={ currentEditingCatalog } hideModal={ hideModal } />
            </Modal>

        </>

    )

}
