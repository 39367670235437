import axios from 'axios';

import { API_URL } from '../../config/settings';
import { requestLogin, validateToken } from '../../api/auth';

export const axiosInstance = axios.create();

export function checkAuth(){

    const token = localStorage.getItem('authToken');

        if(!token){

            return Promise.reject();

        }

        axiosInstance.defaults.headers = {
            'Authorization': token,
        }

        return new Promise((resolve, reject) => {

            validateToken(token, API_URL)
            .then(r => {

                const { auth, token, identity } = r.data;

                if(!auth){

                    return reject(`Su sesión ha expirado. Inicie sesión.`);

                }

                localStorage.setItem("authToken", token);
                localStorage.setItem("identity", JSON.stringify(identity));

                axiosInstance.defaults.headers = {
                    'Authorization': token,
                }

                return resolve(true);

            })
            .catch(reject);

        });


}


export function checkError () { return Promise.resolve(); };


export function getPermissions() {

    const token = localStorage.getItem('authToken');

    if(!token){

        return Promise.reject();

    }

    return Promise.resolve(['Admin']);

}



export function getUserIdentity() {

    const userIdentity = localStorage.getItem('identity');

    if(userIdentity){

        const identity = JSON.parse(userIdentity);

        return Promise.resolve({ name: `${ identity.name } ${ identity.lastname }`, id_adxpace: identity.id_adxpace });
    }

    return Promise.reject();

}


const login = (params) => {

    return new Promise((resolve, reject) => {

        requestLogin({ username: params.username, password: params.password }, API_URL)
        .then(loginResponse => {

            const { auth, token, identity } = loginResponse.data;

            if(!auth){

                reject(`Combinación de usuario y password inválida.`);

            }


            localStorage.setItem("authToken", token);
            localStorage.setItem("identity", JSON.stringify(identity));

            axiosInstance.defaults.headers = {
                'Authorization': token,
            }

            return resolve('/mySpaces');

        })
        .catch(reject);

    });

}


export function logout() {

    localStorage.removeItem('authToken');
    localStorage.removeItem('identity');

    return Promise.resolve();

}


export { login };
