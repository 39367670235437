import React, { useState, useEffect } from "react";

import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, useForm } from "@pankod/refine-antd";
import "./styles.css";

import { useNotification } from "@pankod/refine-core";

import { createUser } from '../../../api/registration';
import logo from '../../../assets/logo_adxpace-propietarios.png';


const { Text, Title } = Typography;


export const Registration = () => {

    const { form, formProps, onFinish, redirect, saveButtonProps } = useForm();
    const { open } = useNotification();

    const [ password, setPassword ] = useState(undefined);
    const [ cPassword, setCPassword ] = useState(undefined);
    const [ isButtonDisabled, setButtonDisabled ] = useState(true);

    saveButtonProps.disabled = true;

    const CardTitle = (
        <Title level={3} className="title">
            Crear cuenta de propietario
        </Title>
    );


    useEffect(() => {

        if(password && cPassword && password === cPassword && password.length > 7){
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

    });


    const registerUser = () => {

        createUser(form.getFieldsValue())
        .then(createUserResponse => {

            if(createUserResponse.data?.err){

                return open({
                    description: 'Error creando cuenta de usuario.',
                    message: createUserResponse.data.err,
                    type: "error"
                })

            }

            open({
                config: {
                    duration: 15,
                    top: 200
                },

                message: 'Por favor verifique su correo para activar su cuenta.',
                description: 'Usuario creado',
                type: "success"
            });

            setButtonDisabled(true);

        })
        .catch(e => {

            console.log(e);

            return open({
                description: 'Error creando cuenta de usuario.',
                message: 'Todos los campos son necesarios.',
                type: "error"
            })

        });


    }



    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={ 22 }>
                    <div className="container">
                        <div className="imageContainer">
                            <img src={ logo } alt="Logo adxpace" />
                        </div>
                        <Card title={ CardTitle } headStyle={{ borderBottom: 0 }}>
                            <Form { ...formProps }
                                layout="vertical"
                                requiredMark={ false }
                                initialValues={{

                                }}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Email, este será su nombre de usuario"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                    onChange={ (e) => setPassword(e.target.value) }
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                    <center><Text type="danger">La longitud m&iacute;nima de su password debe ser de 8 caracteres.</Text></center>
                                </Form.Item>
                                <Form.Item
                                    name="cPassword"
                                    label="Confirmar password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                    onChange={ (e) => setCPassword(e.target.value) }
                                >
                                    <Input.Password
                                        type="password"
                                        size="large"
                                    />

                                </Form.Item>



                                <Form.Item
                                    name="name"
                                    label="Nombre(s)"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Nombre"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="lastname"
                                    label="Apellido(s)"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Apellidos"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="mobile_no"
                                    label="Celular"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Teléfono celular"
                                    />
                                </Form.Item>

                                <div style={{ marginBottom: "12px" }}>
                                    <a
                                        style={{
                                            float: "left",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            marginBottom: '0.75rem',
                                        }}
                                        href="/login"
                                    >
                                        Iniciar sesi&oacute;n
                                    </a>

                                    <a
                                        style={{
                                            float: "right",
                                            fontSize: "14px",
                                        }}
                                        href="#"
                                    >
                                        Ovid&eacute; mi password
                                    </a>
                                </div>

                                <Button
                                    type="primary"
                                    size="large"
                                    block
                                    disabled={ isButtonDisabled }
                                    onClick={ e => registerUser() }
                                >
                                    Registrarme
                                </Button>
                            </Form>

                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
