import axios from 'axios';

import { API_URL } from '../config/settings';


const config = {
	headers: {
		'Content-Type': 'application/json',
	}
}


const createUser = (user) => {

	return axios.post(`${ API_URL }/owners/`, user, config);

}



const requestPasswordReset = (email) => {

	return axios.post(`${ API_URL }/owners/requestPasswordReset`, email, config);

}


const resetUserPassword = (params) => {

	console.log(params);

	return axios.post(`${ API_URL }/owners/resetUserPassword`, params, config);

}



export { createUser, requestPasswordReset, resetUserPassword };
