import { useState, useEffect } from 'react';

import {
    Col,
    DateField,
    DeleteButton,
    EditButton,
    FilterDropdown,
    Image,
    Input,
    List,
    Row,
    Select,
    ShowButton,
    Space,
    Table,
    TagField,
    TextField,
    Typography,
    useTable,
} from "@pankod/refine-antd";

import { useMany, useSelect } from '@pankod/refine-core';

import { getUserIdentity } from '../app/authProvider';

import './styles.css';


const { Title } = Typography;


export const ContractsList = () => {


    const [ id_adxpace, setIdAdxpace ] = useState('');

    const { tableProps } = useTable();


    const statusIds = tableProps.dataSource?.map((record) => record.status.toString()) || [ ];


    const { data: contractStatus, isContractStatusLoading } = useMany({
        ids: statusIds,
        resource: 'contracts/contractsStatus'
    });


    const statusSelectProps = useSelect({
        resource: 'contracts/contractsStatus',
        optionLabel: 'status',
        optionValue: 'id',
        queryOptions: {
            enabled: statusIds.length > 0
        }
    })


    useEffect(() => {

        if(!id_adxpace)

        getUserIdentity()
    	.then(r => {

    		if(r.id_adxpace){

    			setIdAdxpace(r.id_adxpace);

    		}

    	});

    });



    return (

        <>

        {
            id_adxpace ?

            <List>

                <Table { ...tableProps } rowKey="id">

                    <Table.Column dataIndex="id" title="Id solicitud" />

                    <Table.Column
                        dataIndex="status"
                        filterDropdown={ props =>
                            <FilterDropdown { ...props }
                                mapValue={ (selectedKeys) => selectedKeys.map((i) => parseInt(i.toString())) }
                            >
                                <Select
                                    style={{ minWidth: 200 }}
                                    mode="multiple"
                                    { ...statusSelectProps }
                                />
                            </FilterDropdown> }
                        render={ (value) => {

                            if(isContractStatusLoading){
                                return <TextField value="Cargando catalogo..." />
                            }

                            return <TextField value={ contractStatus?.data.find((item) => item.id === value)?.status } />

                        }}
                        title="Estatus" />

                    <Table.Column
                        dataIndex="reqStartDate"
                        render={ (value) => <DateField format="DD/MM/YYYY" value={ value } /> }
                        title="Fecha inicio"
                    />

                    <Table.Column
                        dataIndex="reqEndDate"
                        render={ (value) => <DateField format="DD/MM/YYYY" value={ value } /> }
                        title="Fecha fin"
                    />


                    <Table.Column dataIndex="spaceName" title="Espacio"  />

                    <Table.Column
    					title="Acciones"
    					dataIndex="actions"

    					render={ (_text, record) => (

    						<Space>

    							<EditButton
    								size="small"
    								recordItemId={ record.id }
    							>
                                    Seguimiento
                                </EditButton>

    						</Space>

    					)}
    				/>

                </Table>

            </List>

            :

            <div className="notice">
                <Title level={ 4 }>Para poder administrar solicitudes de contrataci&oacute;n es necesario que ingrese su R. F. C. en la secci&oacute;n Perfil de usuario.</Title>
            </div>

        }

        </>

    )

}
