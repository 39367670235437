import { useState, useEffect } from 'react';

import {
    Badge,
    BadgeProps,
    Calendar,
    Image,
    List,
    Modal,
    Table,
    TagField,
    Typography,
    Show,
} from "@pankod/refine-antd";

import { useApiUrl, useList } from '@pankod/refine-core';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { getUserIdentity } from '../app/authProvider';

import './styles.css';


dayjs.extend(isBetween);

const { Text, Title } = Typography;

function to2(num){

    return `${ num < 10 ? '0' : '' }${ num }`;

}


export const CalendarList = () => {

    const apiUrl = useApiUrl();

    const [ currentItems, setCurrentItems ] = useState({});
    const [ id_adxpace, setIdAdxpace ] = useState('');
    const [ isModalVisible, setIsModalVisible ] = useState(false);



    const { data } = useList({
        resource: `calendar/`
    });



    useEffect(() => {

        if(!id_adxpace)

        getUserIdentity()
    	.then(r => {

    		if(r.id_adxpace){

    			setIdAdxpace(r.id_adxpace);

    		}

    	});

    });


    const badgeColors = ['yellow', 'orange', '#06d806', '#bc5ace'];

    const monthCellRender = (value) => {
        const listData =
            data?.data?.filter((p) => dayjs(p.date).isSame(value, "month")) ??
            [];
        return listData.length > 0 ? (
            <div className="notes-month">
                <section>{ listData.length }</section>
                <span>Espacios reservados</span>
            </div>
        ) : null;
    };


    const dateCellRender = (value) => {

        const listData = data?.data?.filter((p) => dayjs(value).isBetween(new Date(p.startDate), new Date(p.endDate), 'day', '[]'),
        );

        return (
            <ul className="events">
                { listData?.map((item, index) => (
                    <li key={item.id}>
                        <Badge.Ribbon color={ badgeColors[item.reservationStatus - 1] }
                            text={ <Badge color="black" text={ item.id + ' - ' + item.post_title } onClick={ (e) => displayItems(listData, value) } />}
                        />
                    </li>
                )) }
            </ul>
        );
    };


    const displayItems = (list, value) => {

        setCurrentItems({
            date: value,
            list
        });

        setIsModalVisible(true);

    }


    const hideModal = () => {
        setIsModalVisible(false);
    }


    const renderModalContents = () => {

        const { date, list } = currentItems;

        const columns = [
            {
                title: 'ID del espacio',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Espacio',
                dataIndex: 'post_title',
                key: 'espacio'
            },
            {
                title: 'Fecha de inicio',
                dataIndex: 'startDate',
                render: (_, r) => {
                    const date = new Date(r.startDate);
                    return `${ date.getDate() }/${ to2(date.getMonth() + 1) }/${ date.getFullYear() }`;
                }
            },
            {
                title: 'Fecha de fin',
                dataIndex: 'endDate',
                render: (_, r) => {
                    const date = new Date(r.endDate);
                    return `${ date.getDate() }/${ to2(date.getMonth() + 1) }/${ date.getFullYear() }`;
                }
            },
            {
                dataIndex: 'reservationStatus',
                title: 'Estado de la reservación',
                render: (_, record) => <Text>{ record.reservationStatus === 1 ? 'Reservado' : record.reservationStatus === 2 ? 'Confirmado' : 'Contratado' }</Text>
            },
            {
                dataIndex: "image",
                title: "Imagen",
                render: (_, record) => <Image className="thumbnail-img" src={ record.image } />
            },
        ]

        return (
            <List
                itemLayout="vertical"
                size="large"
                title={ `Mostrando espacios involucrados en ${ date.$D }/${ to2(date.$M + 1) }/${ date.$y }` }

            >
                <Table dataSource={ list } columns={ columns } rowKey="id" />
            </List>
        )

    }


    return (

        <>

        {
            id_adxpace ?

            <Show pageHeaderProps={{ extra: null }} title="Calendario de espacios">

                <Calendar
                    dateCellRender={ dateCellRender }
                    monthCellRender={ monthCellRender }
                />

                <Modal onCancel={ hideModal } onOk={ hideModal }  visible={ isModalVisible } width={ 900 }>
                    { isModalVisible && renderModalContents() }
                </Modal>

            </Show>

            :
            <div className="notice">
                <Title level={ 4 }>Para poder administrar los calendarios de sus espacios es necesario que ingrese su R. F. C. en la secci&oacute;n Perfil de usuario.</Title>
            </div>

        }

        </>
    )

}
