import React, { useState, useEffect } from "react";

import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, useForm } from "@pankod/refine-antd";
import "./styles.css";

import { useNotification } from "@pankod/refine-core";

import { requestPasswordReset } from '../../../api/registration';
import logo from '../../../assets/logo_adxpace-propietarios.png';


const { Text, Title } = Typography;


export const ForgotPassword = () => {

    const { form, formProps, onFinish, redirect, saveButtonProps } = useForm();
    const { open } = useNotification();

    const [ email, setEmail ] = useState(undefined);
    const [ isButtonDisabled, setButtonDisabled ] = useState(true);

    saveButtonProps.disabled = true;

    const CardTitle = (
        <Title level={3} className="title">
            Recuperar contraseña
        </Title>
    );


    useEffect(() => {

        if(email && email.length > 7){
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

    });


    const sendPwdResetEmail = () => {

        requestPasswordReset(form.getFieldsValue())
        .then(sndPwdRstEmailResponse => {

            if(sndPwdRstEmailResponse.data?.err){

                return open({
                    description: 'Error solicitando reestablecimiento de contraseña.',
                    message: sndPwdRstEmailResponse.data.err,
                    type: "error"
                })

            }

            open({
                config: {
                    duration: 25,
                    top: 200
                },

                message: 'Se envió un correo con instrucciones para reestablecer su contraseña. Por favor verifique su correo.',
                description: 'Solicitud enviada',
                type: "success"
            });

            setButtonDisabled(true);

        })
        .catch(e => {

            console.log(e);

            return open({
                description: 'Error solicitando reestablecer contraseña.',
                message: 'Todos los campos son necesarios.',
                type: "error"
            })

        });


    }



    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={ 22 }>
                    <div className="container">
                        <div className="imageContainer">
                            <img src={ logo } alt="Logo adxpace" />
                        </div>
                        <Card title={ CardTitle } headStyle={{ borderBottom: 0 }}>
                            <Form { ...formProps }
                                layout="vertical"
                                requiredMark={ false }
                                initialValues={{

                                }}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[{ required: true }]}
                                    onChange={ (e) => setEmail(e.target.value) }
                                >
                                    <Input
                                        size="large"
                                        placeholder="Email con el que se registró"
                                    />
                                </Form.Item>
                                

                                <div style={{ marginBottom: "12px" }}>
                                    <a
                                        style={{
                                            float: "left",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            marginBottom: '0.75rem',
                                        }}
                                        href="/login"
                                    >
                                        Volver a inicio de sesi&oacute;n
                                    </a>

                                </div>

                                <Button
                                    type="primary"
                                    size="large"
                                    block
                                    disabled={ isButtonDisabled }
                                    onClick={ e => sendPwdResetEmail() }
                                >
                                    Enviar
                                </Button>
                            </Form>

                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
