import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Thunk from 'redux-thunk';

import '@fontsource/roboto';

import { App } from './components/app';

import {
    MiddlewareRegistry,
    PersistenceRegistry,
    ReducerRegistry,
    StateListenerRegistry
} from './components/redux';

import reportWebVitals from './reportWebVitals';

import './middlewares';
import './reducers';


function _createStore() {

    const reducer = ReducerRegistry.combineReducers();

    let middleware = MiddlewareRegistry.applyMiddleware(Thunk);

    // let devToolsExtension;

    // if (typeof window === 'object'
    //         && (devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)) {
    //     middleware = compose(middleware, devToolsExtension());
    // }

    const store = createStore(
        reducer, PersistenceRegistry.getPersistedState(), middleware);

    StateListenerRegistry.subscribe(store);

    return store;

}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<Provider store={ _createStore() }>

		<App />

	</Provider>
	,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
