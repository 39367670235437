import { AppstoreOutlined, CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { Refine, AuthProvider, I18nProvider, useSetLocale } from "@pankod/refine-core";
import { Layout, notificationProvider, ErrorComponent } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";

import { useTranslation } from "react-i18next";

import "@pankod/refine-antd/dist/styles.min.css";


import logo from '../../../assets/logo_adxpace-propietarios.png';
import { API_URL } from '../../../config/settings';
import { axiosInstance, checkAuth, checkError, getPermissions, getUserIdentity, login, logout } from '../authProvider';
import { Login } from '../../login';

import { CalendarList } from '../../calendar';
import { ContractsEdit, ContractsList } from '../../contracts';
import { SpacesCreate, SpacesEdit, SpacesList, SpacesShow } from '../../mySpaces';
import { ForgotPassword, Registration, ResetPassword } from '../../registration';
import { OwnersEdit } from '../../user_profile';

import "../i18n.js";
import "./styles.css";


const App: React.FC = () => {

    const authProvider: AuthProvider = {
        checkAuth: checkAuth,
        checkError: checkError,
        getPermissions: getPermissions,
        getUserIdentity: getUserIdentity,
        login: login,
        logout: logout
    };

    const { t, i18n } = useTranslation();




    const i18nProvider = {
        translate: (key, options) => t(key, options),
        changeLocale: (lang) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };


    i18nProvider.changeLocale('es');


    const CustomTitle = ({ collapsed } : { collapsed: any }) => (
        <div >
            { collapsed ? (
                <img
                    src={ logo }
                    alt="ADXpace, zona de propietarios"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "90px",
                        padding: "12px 0",
                        width: "120px"
                    }}
                />
            ) : (
                <img
                    src={ logo }
                    alt="ADXpace, zona de propietarios"
                    style={{
                        backgroundColor: "rgba(250, 250, 250, 0.77)",
                        padding: "12px 24px",
                        width: "200px",
                    }}
                />
            )}
        </div>
    );


    return (
        <Refine
            authProvider={ authProvider }
            catchAll={<ErrorComponent />}
            dataProvider={ dataProvider(API_URL, axiosInstance) }
            i18nProvider={i18nProvider}
            Layout={ Layout }
            LoginPage={ Login }
            notificationProvider={ notificationProvider }
            resources={ [
                {
                    create: SpacesCreate,
                    edit: SpacesEdit,
                    icon: <AppstoreOutlined />,
                    name: 'mySpaces',
                    list: SpacesList,
                    options: { label: 'Mis espacios' },
                    show: SpacesShow,
                },
                {
                    edit: ContractsEdit,
                    list: ContractsList,
                    name: 'contracts',
                    options: { label: 'Mis Solicitudes' }
                },
                {
                    icon: <CalendarOutlined />,
                    name: 'calendar',
                    list: CalendarList,
                    options: { label: 'Calendario' }
                },
                {
                    list: OwnersEdit,
                    icon: <UserOutlined />,
                    name: 'owners',
                    options: { label: 'Perfil de usuario' }
                }
            ] }
            routerProvider={{
                ...routerProvider,
                routes: [
                    {
                        element: <Registration />,
                        path: "/registration"
                    },
                    {
                        element: <ForgotPassword />,
                        path: "/forgotPassword"
                    },
                    {
                        element: <ResetPassword />,
                        path: "/resetPassword/:token"
                    }
                ]
            }}
            Title={ CustomTitle }


        />
    );
};

export default App;
