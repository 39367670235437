import { PersistenceRegistry, ReducerRegistry } from '../redux';
import { SET_USER_LOGGED_IN, SET_USER_AUTH, SET_USER_DATA } from './actionTypes';

const STORE_NAME = 'base/auth';


const DEFAULT_STATE = {

	userLoggedIn: false,
	userAuth: { },
	userData: { }

}


PersistenceRegistry.register(STORE_NAME);


ReducerRegistry.register(
	STORE_NAME,
	(state = DEFAULT_STATE, action) => {

		switch (action.type) {
			case SET_USER_AUTH:
				return {
					...state,
					userAuth: action.userAuth
				}
				// break;


			case SET_USER_DATA:

				return {
					...state,
					userData: action.userData
				}

			// break;


			case SET_USER_LOGGED_IN:
            return {
                ...state,
                userLoggedIn: action.userLoggedIn
            }
			// break;


			default:
				return state;

		}

});
