import { DeleteOutlined } from "@ant-design/icons";
import { Form, getValueFromEvent, Space, Typography, Upload } from "@pankod/refine-antd";
import { useApiUrl, useCreate, useCustom } from '@pankod/refine-core';

const { Text, Title } = Typography;

export const OwnerLogo = ({ logo, ownerId }) => {

    const apiUrl = useApiUrl();

    const { data, refetch } = useCustom({
        config: {
        },
        method: 'get',
        url: `${ apiUrl }/owners/associatedOwners`,

    });

    const fileList = [ ];

    if(logo){

        fileList.push({ uid: 1, name: 'Logo', status: 'done', url: logo, thumbUrl: logo });

    }

    const { mutate } = useCreate();

    return (


            <Form.Item>
                <Form.Item
                    name="logo"
                    valuePropName="fileList"
                    getValueFromEvent={ getValueFromEvent }
                    rules={ [ ] }
                    noStyle
                >
                    <Upload.Dragger
                        defaultFileList={ fileList }
                        name="file"
                        action={`${ apiUrl }/owners/uploadLogo`}
                        headers={{ 'Authorization': localStorage.getItem('authToken') }}
                        listType="picture"
                        maxCount={ 1 }
                    >
                        <Title level={ 5 }>
                            Arrastre un archivo de su logotipo aqu&iacute;.
                        </Title>
                        <Text>
                            <br/>Formatos soportados: <br/>
                        </Text>
                        <Text>
                            <br/>JPG, JPEG y PNG.
                        </Text>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>


    )

}
