import { useEffect } from 'react';
import { useOne } from "@pankod/refine-core";

import { Create, Form, Input, InputNumber, Select, Typography, useForm, useSelect } from "@pankod/refine-antd";

const { Text } = Typography;


const AddToCatalogs = (props) => {

    const { form, formProps, saveButtonProps } = useForm({
        action: "create",
        onMutationSuccess: (data, v) => {
            console.log(data, v);
            props.hideModal(true, v);
        },
        redirect: false,
        resource: "mySpaces/catalogs"
    });


    useEffect(() => {

        form.setFieldsValue({ catalogName: props.catalogName })

    });


    return (

        <Create
            pageHeaderProps={{
                backIcon: false,
                subTitle: null,
                tags: null,
                title: null
            }}
            saveButtonProps={ saveButtonProps }
        >

            <Form { ...formProps }
                initialValues={{
                    catalogName: props.catalogName
                }}
                layout="horizontal"
            >

                <Form.Item
                    hidden
                    label="Catalogo"
                    name="catalogName"
                    value={ props.catalogName }
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="Valor a agregar"
                    name="catalogValue"
                    rules={ [
                        {
                            required: true,
                        }
                    ] }
                >
                    <Input />
                </Form.Item>

            </Form>


        </Create>

    );

}


export default AddToCatalogs;
