import React from "react";
import "@pankod/refine-core";
import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, Checkbox } from "@pankod/refine-antd";
import "./styles.css";

import { useLogin } from "@pankod/refine-core";


import logo from '../../../assets/logo_adxpace-propietarios.png';

const { Text, Title } = Typography;

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Login: React.FC = () => {

    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={3} className="title">
            Inicio de sesi&oacute;n
        </Title>
    );

    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={ 22 }>
                    <div className="container">
                        <div className="imageContainer">
                            <img src={ logo } alt="Logo adxpace" />
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={ false }
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <Form.Item
                                    name="username"
                                    label="Email"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Email con el que se registró"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"

                                    />
                                </Form.Item>
                                <div style={{ marginBottom: "1rem" }}>
                                    <Text><br/>A&uacute;n no tienes cuenta?</Text><br/>
                                    <a
                                        style={{
                                            float: "left",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            marginBottom: "0.75rem"
                                        }}
                                        href="/registration"
                                    >
                                        Registrarme
                                    </a>

                                    <a
                                        style={{
                                            float: "right",
                                            fontSize: "14px",
                                        }}
                                        href="/forgotPassword"
                                    >
                                        Olvid&eacute; mi password
                                    </a>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                >
                                    Iniciar sesi&oacute;n
                                </Button>
                            </Form>

                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
