import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, getValueFromEvent, Input, Row, Tooltip, Typography, Upload } from "@pankod/refine-antd";
import { useApiUrl, useCustom, useUpdate } from '@pankod/refine-core';

import { AssociatedOwners } from './associatedOwners';
import { OwnerLogo } from './ownerLogo';

import './styles.css';

const { Text, Title } = Typography;


export const OwnersEdit = () => {

    const apiUrl = useApiUrl();

    const { data, refetch } = useCustom({
        config: {
        },
        method: 'get',
        url: `${ apiUrl }/owners/me`,

    });

    const { mutate } = useUpdate();


    const userData = data?.data;


    const onFinish = (values: any) => {

        mutate({
            resource: 'owners',
            id: 'me',
            values
        },{
            onSuccess: () => {

                refetch();

            }
        });

    };


    const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
            },
        },
    };


    const associatedUsersHelp = `Asociar usuarios a su cuenta le permitirá que dichos usuarios puedan realizar acciones en su nombre como consultar espacios, darlos de alta y realizar el seguimiento de solicitudes de contrataciión.`;

    const logoHelp = `El logotipo será utilizado para conformar sus cotizaciones y documentos importantes hacia ADXpace.`

    return (

        <div className="form-container">

            <Title level={ 4 }>Configuraci&oacute;n de la cuenta</Title> <br/>

            <Row>

                <Col span={ 9 }>

                    {
                        userData &&

                        <Form
                            initialValues={{
                                lastname: userData.lastname,
                                mobile_no: userData.mobile_no,
                                name: userData.name,
                                rfc: userData.rfc,
                                username: userData.email
                            }}
                            labelCol={{ span: 12 }}
                            layout="vertical"
                            onFinish={ onFinish }
                            wrapperCol={{ span: 20 }}
                        >

                            <Form.Item
                                label="Nombre de usuario (email):"
                                name="username"
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Nombre(s) / Raz&oacute;n social:"
                                name="name"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Apellido(s):"
                                name="lastname"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Tel&eacute;fono m&oacute;vil:"
                                name="mobile_no"
                            >
                                <Input />
                            </Form.Item>

                            {
                                userData.parent === 0 &&
                                <Form.Item
                                    label="R. F. C."
                                    name="rfc"
                                    rules={[
                                        { required: true },
                                        {
                                            validator: (_, value) => {
                                                if(!value) return;

                                                const rfcRE = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:(?:0[1-9]|1[0-2])(?:0[1-9]|1[0-9]|2[0-8])|(?:0[469]|11)(?:29|30)|(?:0[13578]|1[02])(?:29|3[01]))|(?:0[048]|[2468][048]|[13579][26])0229) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

                                                const rfcValidFormat = value.trim().toUpperCase().match(rfcRE)

                                                if(!rfcValidFormat){

                                                    return Promise.reject(new Error("Ingrese un R. F. C. válido"));

                                                }


                                                const dv = rfcValidFormat.pop();
                                                const unsignedRFC = rfcValidFormat.slice(1).join('');
                                                const unsignedLength = unsignedRFC.length;

                                                const dictionary = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ";
                                                const index = unsignedLength + 1;

                                                let sum, expectedDigit;

                                                sum = unsignedLength === 12 ? 0 : 481; // SAT rules, don't ask me.

                                                for(let i = 0; i < unsignedLength; i++){

                                                    sum += dictionary.indexOf(unsignedRFC.charAt(i)) * (index - i);

                                                }

                                                expectedDigit = 11 - sum % 11;


                                                if(expectedDigit === 11) expectedDigit = 0;
                                                else if (expectedDigit === 10) expectedDigit = 'A';

                                                if(dv != expectedDigit || unsignedRFC + dv === 'XAXX010101000' || unsignedRFC + dv === 'XEXX010101000')
                                                    return Promise.reject(new Error("Ingrese un R. F. C. válido"));

                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            }


                            <Form.Item { ...tailFormItemLayout }>
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Form.Item>

                        </Form>

                    }

                </Col>

                <Col span={ 15 }>

                    {

                        userData?.parent === 0 && userData.rfc &&

                        <>

                            <br/>

                            <Title level={ 5 }>Usuarios asociados &nbsp; <Tooltip title={ associatedUsersHelp } placement="right"><QuestionCircleOutlined /></Tooltip> </Title>

                            <br/>

                            <AssociatedOwners ownerId={ userData.id } />

                        </>

                    }

                    {

                        userData?.parent !== 0 &&
                        <>

                            <Title level={ 4 }>Su usuario forma parte de un conjunto de cuentas.</Title>

                        </>

                    }

                </Col>

            </Row>

            <Row>

                <Title level={ 4 }>Archivos</Title><br/>

            </Row>

            <Row>

                <Col className="logo" span={ 9 }>

                    {

                        userData?.parent === 0 && userData.rfc &&

                        <>

                            <br/>

                            <Title level={ 5 }>Logotipo <Tooltip title={ logoHelp } placement="right"><QuestionCircleOutlined /></Tooltip> </Title>

                            <br/>

                            <OwnerLogo logo={ userData.logo } ownerId={ userData.id } />

                        </>

                    }

                    {

                        userData?.parent !== 0 &&
                        <>

                            <Title level={ 4 }>Su usuario forma parte de un conjunto de cuentas.</Title>

                        </>

                    }

                </Col>

            </Row>

        </div>

    )

}
