import React, { useState, useEffect } from "react";

import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, useForm } from "@pankod/refine-antd";
import "./styles.css";

import { useNotification } from "@pankod/refine-core";
import { useParams } from "react-router-dom";

import { resetUserPassword } from '../../../api/registration';
import logo from '../../../assets/logo_adxpace-propietarios.png';


const { Text, Title } = Typography;


export const ResetPassword = () => {

    const { form, formProps, onFinish, redirect, saveButtonProps } = useForm();
    const { open } = useNotification();
    let { token } = useParams();


    const [ password, setPassword ] = useState(undefined);
    const [ cPassword, setCPassword ] = useState(undefined);
    const [ isButtonDisabled, setButtonDisabled ] = useState(true);

    saveButtonProps.disabled = true;

    const CardTitle = (
        <Title level={3} className="title">
            Restablecer contraseña
        </Title>
    );


    useEffect(() => {

        if(password && cPassword && password === cPassword && password.length > 7){
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

    });



    const sendPasswordResetRequest = () => {

        resetUserPassword(form.getFieldsValue())
        .then(sndPwdRstEmailResponse => {

            if(sndPwdRstEmailResponse.data?.err){

                return open({
                    config: {
                        duration: 45,
                        top: 200
                    },
                    description: 'Error solicitando reestablecimiento de contraseña.',
                    message: sndPwdRstEmailResponse.data.err,
                    type: "error"
                })

            }

            open({
                config: {
                    duration: 25,
                    top: 200
                },

                message: 'Regrese a Iniciar sesión e ingrese con su nuevo password..',
                description: 'Solicitud aceptada',
                type: "success"
            });

            setButtonDisabled(true);

        })
        .catch(e => {

            console.log(e);

            return open({
                description: 'Error solicitando reestablecer contraseña.',
                message: 'Todos los campos son necesarios.',
                type: "error"
            })

        });


    }



    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={ 22 }>
                    <div className="container">
                        <div className="imageContainer">
                            <img src={ logo } alt="Logo adxpace" />
                        </div>
                        <Card title={ CardTitle } headStyle={{ borderBottom: 0 }}>
                            <Form { ...formProps }
                                layout="vertical"
                                requiredMark={ false }
                                initialValues={{
                                    token
                                }}
                            >
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                    onChange={ (e) => setPassword(e.target.value) }
                                >
                                    <Input.Password
                                        type="password"
                                        size="large"
                                    />
                                    <center><Text type="danger">La longitud m&iacute;nima de su password debe ser de 8 caracteres.</Text></center>
                                </Form.Item>

                                <Form.Item
                                    name="cPassword"
                                    label="Confirmar password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                    onChange={ (e) => setCPassword(e.target.value) }
                                >
                                    <Input.Password
                                        type="password"
                                        size="large"
                                    />

                                </Form.Item>


                                <Form.Item
                                    name="token"
                                    label="token"
                                    style={{ display: 'none' }} 
                                    value={ token }
                                    defaultValue={ token }
                                >
                                    <Input />
                                </Form.Item>
                                

                                <div style={{ marginBottom: "12px" }}>
                                    <a
                                        style={{
                                            float: "left",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            marginBottom: '0.75rem',
                                        }}
                                        href="/login"
                                    >
                                        Volver a inicio de sesi&oacute;n
                                    </a>

                                </div>

                                <Button
                                    type="primary"
                                    size="large"
                                    block
                                    disabled={ isButtonDisabled }
                                    onClick={ e => sendPasswordResetRequest() }
                                >
                                    Enviar
                                </Button>
                            </Form>

                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
