import { useState, useEffect } from 'react';

import {
    Col,
    DeleteButton,
    EditButton,
    FilterDropdown,
    Image,
    Input,
    List,
    Row,
    ShowButton,
    Space,
    Table,
    TagField,
    Typography,
    useTable,
} from "@pankod/refine-antd";

import { getUserIdentity } from '../app/authProvider';

import { OwnerSpacesMap } from './';

import './styles.css';


const { Title } = Typography;


export const SpacesList = () => {

    const [ id_adxpace, setIdAdxpace ] = useState('');

    const { tableProps } = useTable();

    console.log(tableProps);


    useEffect(() => {

        if(!id_adxpace)

        getUserIdentity()
    	.then(r => {

    		if(r.id_adxpace){

    			setIdAdxpace(r.id_adxpace);

    		}

    	});

    });


    return (

        <>

        {
            id_adxpace ?

            <List>

                <Row>

                    <Col span={ 16 }>

                        <Table { ...tableProps } rowKey="id">

                            <Table.Column dataIndex="id" title="Id en BD" />

                            <Table.Column dataIndex="_cth_codigo" title="Id-ADXpace" filterDropdown={ props => <FilterDropdown { ...props }> <Input /> </FilterDropdown> } />

                            <Table.Column dataIndex="post_title" title="Nombre (domicilio)" filterDropdown={ props => <FilterDropdown { ...props }> <Input /> </FilterDropdown> } />

                            <Table.Column dataIndex="post_type" title="Tipo de espacio" render={ (value) => <TagField value={ value === 'listing' ? 'OOH Fijo' : 'Multiespacio' } /> } />

                            <Table.Column dataIndex="status" title="Estado" render={ (value) => <TagField value={ value === 'publish' ? 'Aprobado' : 'Pendiente de aprobación' } /> } />

                            <Table.Column dataIndex="image" title="Imagen" render={(_, record) => <Image className="thumbnail-img" src={ record.image } />} />

                            <Table.Column
            					title="Acciones"
            					dataIndex="actions"

            					render={ (_text, record) => (

            						<Space>

                                        <ShowButton
            								size="small"
            								recordItemId={ record.id }
            								hideText
            							/>

            							<EditButton
            								hideText
            								size="small"
            								recordItemId={ record.id }
            							/>

            							<DeleteButton
                                            hideText
            								size="small"
            								recordItemId={ record.id }
            							/>

            						</Space>

            					)}
            				/>

                        </Table>

                    </Col>

                    <Col span={ 8 }>
                        <OwnerSpacesMap spaces={ tableProps.dataSource } />
                    </Col>

                </Row>

            </List>

            :

            <div className="notice">
                <Title level={ 4 }>Para poder administrar sus espacios es necesario que ingrese su R. F. C. en la secci&oacute;n Perfil de usuario.</Title>
            </div>

        }

        </>

    )

}
