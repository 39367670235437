import React, { useState, useEffect } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";


const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    if (
		isLatLngLiteral(a) ||
		a instanceof window.google.maps.LatLng ||
		isLatLngLiteral(b) ||
		b instanceof window.google.maps.LatLng
    ) {
		return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    return deepEqual(a, b);

  }

);



function useDeepCompareMemoize(value) {
	const ref = React.useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {

	React.useEffect(callback, dependencies.map(useDeepCompareMemoize));

}


const Marker = (options) => {

	const [marker, setMarker] = React.useState();

	React.useEffect(() => {
		if (!marker) {
			setMarker(new window.google.maps.Marker());
		}

		// remove marker from map on unmount
		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};

	}, [marker]);

		React.useEffect(() => {
		if (marker) {
			marker.setOptions(options);
		}

	}, [marker, options]);

	return null;

};


const Map: React.FC<MapProps> = ({
	onClick,
	onIdle,
	children,
	style,
	...options
	}) => {

		const ref = React.useRef(null);
		const [map, setMap] = React.useState();

		React.useEffect(() => {
			if (ref.current && !map) {
				setMap(new window.google.maps.Map(ref.current, {}));
			}
		}, [ref, map]);

		// because React does not do deep comparisons, a custom hook is used
		// see discussion in https://github.com/googlemaps/js-samples/issues/946
		useDeepCompareEffectForMaps(() => {
			if (map) {
				map.setOptions(options);
			}
		}, [map, options]);

		React.useEffect(() => {

			if (map) {
				["click", "idle"].forEach((eventName) =>
					window.google.maps.event.clearListeners(map, eventName)
				);

				if (onClick) {
					map.addListener("click", onClick);
				}

				if (onIdle) {
					map.addListener("idle", () => onIdle(map));
				}
			}

		}, [map, onClick, onIdle]);

		return (
			<>
				<div ref={ref} style={style} />

				{React.Children.map(children, (child) => {
					if (React.isValidElement(child)) {
					// set the map prop on the child component
					// @ts-ignore
						return React.cloneElement(child, { map });
					}
				})}
			</>
		);
};


export const OwnerSpacesMap = (props) => {

	const [clicks, setClicks] = React.useState([]);
	const [zoom, setZoom] = React.useState(3); // initial zoom
	const [center, setCenter] = React.useState({
		lat: 25.444348,
		lng: -100.977792,
	}); 


	const onClick = (e) => {
    // avoid directly mutating state
    	setClicks([...clicks, e.latLng]);
	};


	const onIdle = (m) => {
	console.log("onIdle");
		setZoom(m.getZoom());
		setCenter(m.getCenter().toJSON());
	};


	const render = (status) => {
		return <h1>{status}</h1>;
	};

	let markers = props.spaces?.length &&
		props.spaces.map((space, i) => 
			{
				return {
					lat: parseFloat(space._cth_latitude),
					lng: parseFloat(space._cth_longitude)
				}
			}
			)
		|| [ ];



	return (
		<div style={{ display: "flex", height: "100%" }}>

			<Wrapper apiKey='AIzaSyC08JgrzWxyDx9schBiffvd9YdoOTKwSLM' render={ render }>

				<Map
					center={ center }
					onClick={ onClick }
					onIdle={ onIdle }
					zoom={ zoom }
					style={{ flexGrow: "1", height: "100%" }}
				>

					{ 
						markers.map((marker, i) => {
							return <Marker key={`marker_${i}`} position={marker} />
						}
							
						)
						
					}

				</Map>

			</Wrapper>

		</div>
	);

}