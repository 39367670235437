import { useState } from 'react';
import { useApiUrl, useMany, useUpdate } from "@pankod/refine-core";

import {
    Button,
    Col,
    Edit,
    Form,
    getValueFromEvent,
    Image,
    Input,
    InputNumber,
    Row,
    SaveButton,
    Tooltip,
    Typography,
    Upload,
    useForm,
    } from "@pankod/refine-antd";

import { API_URL } from '../../config/settings.js';

import './styles.css';

const { Text, Title } = Typography;


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


function to2(num){

    return `${ num < 10 ? '0' : '' }${ num }`;

}



export const ContractsEdit = () => {

    const apiUrl = useApiUrl();

    const [ currentPrice, setCurrentPrice ] = useState();

    const { formProps, queryResult, saveButtonProps } = useForm();

    const { data, refetch } = queryResult;

	const record = data?.data;


    const { data: contractEvidence } = useMany({
        queryOptions: {
            enabled: record !== undefined && record.status === 7,
        },
        resource: `contracts/contractEvidence?id=${ record?.id }`,
    });


    const { data: contractStatus } = useMany({
        resource: 'contracts/contractsStatus',
        queryOptions: {
            enabled: record !== undefined
        }
    });


    const { data: contractMetaCatalog } = useMany({
        resource: 'contracts/contractMetaCatalog',
        queryOptions: {
            enabled: record !== undefined
        }
    });




    const currentEvidence = contractEvidence?.data.map((r, i) => { return { uid: i, name: `Evidencia ${ i + 1 }`, status: 'done', thumbUrl: r.evidence, url: r.evidence } });

    const contractInvoice = [ ];

    if(record?.ownerInvoiceXML){

        contractInvoice.push({ uid: '0001', name: 'Archivo XML', status: 'done', url: `${ API_URL.replace('propietarios.', '').replace('api', '') }spaces/wp-content/uploads/${ record.ownerInvoiceXML }` });

    }

    if(record?.ownerInvoicePDF){

        contractInvoice.push({ uid: '0002', name: 'Archivo PDF', status: 'done', url: `${ API_URL.replace('propietarios.', '').replace('api', '') }spaces/wp-content/uploads/${ record.ownerInvoicePDF }` });

    }


    const { mutate } = useUpdate();

    const onFinish = (values: any) => {

        switch(values.status){

            case 3:
                if(values.quotePrice)
                    values.status = 4;
            break;

            case 4:
                if(!values.quotePrice)
                return false;
            break;

            case 5:
                values.status = 6;
            break;

            case 6:
                values.status = 7;
            break;

            case 90:
                values.status = 90;
            break;

            case 91:
                values.status = 91;
            break;


            default:
                console.log(`Status not yet supported.`);
                return false;

        }


        mutate({
            resource: 'contracts',
            id: record.id,
            values
        },{
            onSuccess: () => {

                refetch();

            }
        });

    };



    const renderOptions = () => {

        if(!record){

            return (<Title level={ 4 }>Cargando...</Title>);

        }


        switch (record.status){

            case 2:
                return (
                    <>
                        <Form.Item
                            hidden
                            label="Estatus"
                            name="status"
                            value="3"
                        >
                            <Input disabled />
                        </Form.Item>
                        <Button shape="round" size="large" style={{ background: '#0E253F', color: '#dddddd' }} onClick={ () => onFinish({ status: 3 }) }>Establecer y Notificar cotizaci&oacute;n en progreso</Button>
                    </>
                )
                break;


            case 3:
                return (
                    <>

                        <Form.Item
                            hidden
                            label="Estatus"
                            name="status"
                            value={ 4 }
                        >
                            <Input  />
                        </Form.Item>


                        <Tooltip title={ `El precio debe incluir el 15% de comisión para ADXpace.` } placement="right">
                            <Form.Item
                                label="Precio (MN)"
                                name="quotePrice"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(25% - 8px)', marginRight: '4px' }}
                            >

                                <InputNumber onChange={ val => setCurrentPrice(val) }/>

                            </Form.Item>
                        </Tooltip>

                        {
                            contractMetaCatalog?.data?.length > 0 &&
                            <Title level={ 4 }>
                                Condiciones de contratación
                            </Title>
                        }

                        {
                            contractMetaCatalog?.data?.length > 0 &&
                            contractMetaCatalog?.data?.map((quotationMeta) =>

                                <Form.Item
                                    key={ `quoteMetaKey_${ quotationMeta.id }` }
                                    label={ quotationMeta.description }
                                    name={ `quoteMeta_${ quotationMeta.id }` }
                                    style={{ display: 'inline-block', width: 'calc(75% - 8px)', marginRight: '4px' }}
                                    value={ record[`quoteMeta_${ quotationMeta.id }`] }
                                >
                                    <Input />
                                </Form.Item>

                            )

                        }

                    </>

                )

            break;

            case 4:

                return(
                    <>
                        <Text strong>Cotizaci&oacute;n actual: </Text> &nbsp; <Text> $ { numberFormat(record.quotedPrice, 0) }</Text>
                        <br/> &nbsp; <br/>



                        <Form.Item
                            hidden
                            label="Estatus"
                            name="status"
                            value="4"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Tooltip title={ `El precio debe incluir el 15% de comisión para ADXpace.` } placement="right">
                            <Form.Item
                                label="Nuevo precio (MN)"
                                name="quotePrice"
                                rules={ [ { required: true } ] }
                            >

                                <InputNumber onChange={ val => setCurrentPrice(val) }/>

                            </Form.Item>
                        </Tooltip>

                        {
                            contractMetaCatalog?.data?.length > 0 &&
                            <Title level={ 4 }>
                                Condiciones de contratación
                            </Title>
                        }

                        {

                            contractMetaCatalog?.data?.length > 0 &&
                            contractMetaCatalog?.data?.map((quotationMeta) =>

                                <Form.Item
                                    key={ `quoteMetaKey_${ quotationMeta.id }` }
                                    label={ quotationMeta.description }
                                    name={ `quoteMeta_${ quotationMeta.id }` }
                                    style={{ display: 'inline-block', width: 'calc(75% - 8px)', marginRight: '4px' }}
                                    value={ record[`quoteMeta_${ quotationMeta.id }`] }
                                >
                                    <Input />
                                </Form.Item>

                            )

                        }

                    </>
                )

            break;


            case 5:
                return (
                    <>
                        <Form.Item
                            hidden
                            label="Estatus"
                            name="status"
                            value="5"
                        >
                            <Input disabled />
                        </Form.Item>
                        <Button shape="round" size="large" style={{ background: '#0E253F', color: '#dddddd' }} onClick={ () => onFinish({ status: 5 }) }>Establecer y Notificar contrato en progreso</Button>
                    </>
                )
                break;




            case 6:

                const fileList = [ ];

                if(record.contractDoc){
                    fileList.push({ uid: 1, name: 'contract', status: 'done', url: record.contractDoc });
                }

                return (
                    <>

                        <Form.Item
                            hidden
                            label="Estatus"
                            name="status"
                            value="7"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item>
                            <Form.Item
                                name="contract"
                                valuePropName="fileList"
                                getValueFromEvent={ getValueFromEvent }
                                rules={ [ { required: true } ] }
                                noStyle
                            >
                                <Upload.Dragger
                                    accept=".doc,.docx,.pages"
                                    defaultFileList={ fileList }
                                    name="file"
                                    action={`${ apiUrl }/contracts/uploadContract`}
                                    headers={{ 'Authorization': localStorage.getItem('authToken') }}
                                    listType="text"
                                    maxCount={ 1 }
                                >
                                    <Title level={ 5 }>
                                        Arrastre archivo con su contrato aqu&iacute;.
                                    </Title>
                                    <Text>
                                        <br/>O haga click para seleccionar un archivo.<br/>&nbsp;<br/>Formatos soportados: <br/>
                                    </Text>
                                    <Text strong>
                                        Word, Pages.
                                    </Text>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>

                    </>
                )

            break;


            case 7:

                const ownerInvoiceXML = record?.ownerInvoiceXML;
                const ownerInvoicePDF = record?.ownerInvoicePDF;

                return (
                    <Row gutter={ 0 }>
                        <Col span={ 12 }>

                            <Form.Item>
                                <Form.Item
                                    name="pdfInvoice"
                                    valuePropName="ownerInvoiceXML"
                                    getValueFromEvent={ getValueFromEvent }
                                    rules={ [ { required: true } ] }
                                    noStyle
                                >
                                    <Upload.Dragger
                                        accept=".pdf,.xml"
                                        name="file"
                                        action={`${ apiUrl }/contracts/uploadInvoice`}
                                        data={{ contractId: record.id }}
                                        defaultFileList={ contractInvoice }
                                        headers={{ 'Authorization': localStorage.getItem('authToken') }}
                                        maxCount={ 2 }
                                        multiple
                                    >
                                        <Title level={ 5 }>
                                            Arrastre los archivos corrrespondientes a su factura aquí.
                                        </Title>
                                        <Text>
                                            <br/>O haga click para seleccionar un archivo.<br/>&nbsp;<br/>Formatos soportados: <br/>
                                        </Text>
                                        <Text strong>
                                            XML, PDF
                                        </Text>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Form.Item>
                            <center>
                                
                                    <Button shape="round" size="large" style={{ background: '#0E253F', color: '#dddddd' }} onClick={ () => onFinish({ status: 91 }) }>Enviar factura</Button>
                                
                            </center>

                        </Col>
                        <Col span={ 12 }>
                            <Form.Item
                                hidden
                                label="Estatus"
                                name="status"
                                value="7"
                            >
                                <Input disabled />
                            </Form.Item>


                            <Form.Item>
                                <Form.Item
                                    name="deployEvidence"
                                    valuePropName="currentEvidence"
                                    getValueFromEvent={ getValueFromEvent }
                                    rules={ [ { required: true } ] }
                                    noStyle
                                >
                                    <Upload.Dragger
                                        accept=".jpg,.jpeg,.png"
                                        name="file"
                                        action={`${ apiUrl }/contracts/uploadEvidence`}
                                        data={{ contractId: record.id }}
                                        defaultFileList={ currentEvidence }
                                        headers={{ 'Authorization': localStorage.getItem('authToken') }}
                                        listType="picture"
                                        maxCount={ 5 }
                                        multiple
                                    >
                                        <Title level={ 5 }>
                                            Una vez desplegado, arrastre archivo con la evidencia (foto) del despliegue de la publicidad en el espacio contratado.
                                        </Title>
                                        <Text>
                                            <br/>O haga click para seleccionar un archivo.<br/>&nbsp;<br/>Formatos soportados: <br/>
                                        </Text>
                                        <Text strong>
                                            JPG, JPEG, PNG.
                                        </Text>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Form.Item>

                            <center>
                                <Button shape="round" size="large" style={{ background: '#0E253F', color: '#dddddd' }} onClick={ () => onFinish({ status: 90 }) }>Enviar evidencia</Button>
                            </center>
                        </Col>
                    </Row>
                )



            default:
                return null;


        }



    }




    let formattedStartDate, formattedEndDate, quotePrice;

    if(record){

        const { reqEndData, reqStartDate } = record;

        const startDate = new Date(reqStartDate);
        const endDate = new Date(reqEndData);

        formattedStartDate = `${ startDate.getDate() }/${ to2(startDate.getMonth() + 1) }/${ startDate.getFullYear() }`;
        formattedEndDate = `${ endDate.getDate() }/${ to2(endDate.getMonth() + 1) }/${ endDate.getFullYear() }`;

        quotePrice = record.quotedPrice ? numberFormat(record.quotedPrice, 2) : null;

    }




    return (

        <Edit title={`Seguimiento la solicitud ${ record?.id }`}
            actionButtons={ record?.status > 2 && record?.status < 7 && <SaveButton { ...saveButtonProps } shape="round" size="large" style={{ background: '#0E253F', color: '#dddddd', alignSelf: 'center middle' }}>Guardar</SaveButton>} >


            <Row gutter={[ 8, 32 ]}>

                <Col className="centered" span={ 24 }>

                    {
                        contractStatus?.data.length > 0 &&
                        <Title level={ 4 }>
                            <Text>Estatus actual - </Text><Text strong>{ contractStatus.data.find(el => el.id === record?.status)?.status }</Text>
                        </Title>
                    }

                </Col>



                <Col offset={ quotePrice ? 4 : 7 } span={ 6 }>

                    <Text>Fecha inicio: <strong>{ formattedStartDate }</strong></Text>

                </Col>

                <Col offset={ 0 } span={ 6 }>

                    <Text>Fecha fin: <strong>{ formattedEndDate }</strong></Text>

                </Col>

                {
                    quotePrice !== null &&
                    <Col offset={ 0 } span={ 6 }>

                        <Text>Precio cotizado: <strong>$ { quotePrice }</strong></Text>

                    </Col>
                }
                

            </Row>

            <Row gutter={[ 8, 32 ]}>


                <Col span={ 7 }>

                    <Row>

                        {
                            record?.pictures.map((image, imageIndex) =>

                                <Col className="guttered-row" key={ `spaceImage${ imageIndex }` } span={ imageIndex === 0 ? 24 : 12 }>
                                    <Image src={ image } />
                                </Col>

                            )
                        }

                    </Row>

                    <Row>

                        <Title level={ 5 }>{ record?.postTitle }</Title>
                    </Row>
                    <Row>
                        <Text strong>Precio publicado: </Text><Text> &nbsp; $ { record && numberFormat(record?.price, 0) }</Text>
                    </Row>
                    {
                        record?.paymentFreq &&
                        <Row>
                            <Text strong>Periodo de pago publicado: </Text><Text> &nbsp; { record?.paymentFreq } d&iacute;as</Text>
                        </Row>
                    }

                    {
                        record?.contractPeriod &&
                        <Row>
                            <Text strong>Periodo de contrataci&oacute;n publicado: </Text><Text> &nbsp; { record?.contractPeriod } d&iacute;as</Text>
                        </Row>
                    }


                </Col>


                <Col className="contractProcess" offset={ 1 } span={ 16 }>

                    <Form
                        { ...formProps }
                        labelCol={{ span: 12 }}
                        layout="vertical"
                        onFinish={ onFinish }
                        wrapperCol={{ span: 20 }}
                    >

                        { renderOptions() }

                    </Form>

                </Col>

            </Row>

        </Edit>

    )

}
