import { useState } from 'react';
import { useApiUrl, useMany, useNotification, useUpdate } from "@pankod/refine-core";

import { AntdLayout, Button, Checkbox, Col, Edit, Form, getValueFromEvent, Image, Input, InputNumber, Modal, Row, SaveButton, Select, Typography, Upload, useForm, useSelect } from "@pankod/refine-antd";

import AddToCatalogs from './addToCatalogs';

import './styles.css';

const { Text, Title } = Typography;


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


function to2(num){

    return `${ num < 10 && '0' }${ num }`;

}


export const SpacesEdit = () => {

    const apiUrl = useApiUrl();
    const { form, formProps, queryResult, saveButtonProps } = useForm();
    const { open } = useNotification();
    const { data, refetch } = queryResult;
    const record = data?.data;

    const [ category, setCategory ] = useState('');
    const [ currentEditingCatalog, setCurrentEditingCatalog ] = useState(null);
    const [ currentEditingResource, setCurrentEditingResorce ] = useState(null);
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [ subcategory, setSubcategory ] = useState('');
    const [ subcategory1, setSubcategory1 ] = useState('');
    const [ supercategory, setSupercategory ] = useState('');


    const hideModal = (shouldRefetch, value) => {

        setIsModalVisible(false);

        if(shouldRefetch && currentEditingResource){

            currentEditingResource.queryResult.refetch();
            setCurrentEditingResorce(null);
            form.setFieldsValue( { [ value.catalogName.toLowerCase() ]: value.catalogValue } );

        }

    };

    const displayModal = () => { setIsModalVisible(true) };


    const spaceTypes = useSelect({
        // defaultValue: record?._cth_listing_type_id,
        optionLabel: 'post_title',
        optionValue: 'id',
        queryOptions: {
            select: (data) => {
                data.data.map(el => el.id = el.ID.toString())
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/spaceTypes'
    });


    const orientaciones = useSelect({
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        optionLabel: 'option_value',
        optionValue: 'option_value',
        showSearch: false,
        resource: 'mySpaces/catalogs/orientacion'
    });


    const vistas = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/vista'
    });

    const estructura = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/estructura'
    });


    const nivel_socioeconomico_zona = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/nivel_socioeconomico_zona'
    });


    const nivel_socioeconomico_audiencia = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/nivel_socioeconomico_audiencia'
    });


    const tipo_establecimiento = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/tipo_establecimiento'
    });


    const trafico_vehicular = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/trafico_vehicular'
    });


    const vialidad = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/vialidad'
    });


    const visibilidad = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/visibilidad'
    });


    const calificacion = useSelect({
        optionLabel: 'option_value',
        optionValue: 'option_value',
        queryOptions: {
            select: (data) => {
                data.data.push({ option_value: ' -- Agregar otro valor' });
                data.total = data.data.length;
                return data;
            }
        },
        resource: 'mySpaces/catalogs/calificacion'
    });


    const supercategoria = useSelect({
        optionLabel: 'termName',
        optionValue: 'termId',
        resource: 'mySpaces/categories/0'
    });


    const categoria = useSelect({
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: supercategory !== '' || record?.supercategoria !== undefined
        },
        resource: `mySpaces/categories/${ supercategory || record?.supercategoria }`
    });


    const subcategoria = useSelect({
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: category !== '' || record?.categoria !== undefined
        },
        resource: `mySpaces/categories/${ category || record?.categoria }`
    });


    const subcategoria1 = useSelect({
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: subcategory !== '' || record?.subcategoria !== undefined
        },
        resource: `mySpaces/categories/${ subcategory || record?.subcategoria }`
    });


    const subcategoria2 = useSelect({
        optionLabel: 'termName',
        optionValue: 'termId',
        queryOptions: {
            enabled: subcategory !== '' || record?.subcategoria1 !== undefined
        },
        resource: `mySpaces/categories/${ subcategory1 || record?.subcategoria1 }`
    });


    const _renderEspacioPublicitario = () => {

        return (

            <AntdLayout>

                <AntdLayout.Sider
                    breakpoint="xs"
                    className="inner-sider"
                    width="400"
                >


                    <Form.Item>
                        <Form.Item
                            name="images"
                            valuePropName="fileList"
                            getValueFromEvent={ getValueFromEvent }
                            noStyle
                            rules={ [
                                { required: true },
                                {
                                    validator: async(_, value) => {
                                        // console.log(_, value);
                                        if(!value || value.length === 0){
                                            return Promise.reject(new Error("'Agregue al menos una imagen para su espacio.'"))
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ] }
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${ apiUrl }/spaces/upload`}
                                headers={{ 'Authorization': localStorage.getItem('authToken') }}
                                listType="picture"
                                maxCount={ 5 }
                                multiple
                            >
                                <Title level={ 5 }>
                                    Arrastre los archivos de sus fotos aqu&iacute;.
                                </Title>
                                <Text>
                                    <br/>Usted puede agregar hasta 5 archivos para su espacio. <br/>
                                </Text>
                                <Text>
                                    <br/>Siempre recomendamos subir 3 im&aacute;genes por espacio.
                                </Text>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                </AntdLayout.Sider>

                <AntdLayout.Content
                    className="inner-content"
                >

                    <Form.Item
                        label="C&oacute;digo de tu espacio"
                        name="_cth_codigo"
                        rules={ [ { required: true } ] }
                        wrapperCol={{ span: 7 }}
                    >
                        <Input />
                    </Form.Item>

                    <br/>

                    <Title level={ 5 } className="add-item-required">Domicilio del espacio</Title>

                    <Form.Item style={{ marginBottom: 0 }}>

                        <Form.Item

                            name="_cth_calle"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
                        >
                            <Input placeholder="Calle" />
                        </Form.Item>

                        <Form.Item

                            name="_cth_numero"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="N&uacute;mero o S/N" />
                        </Form.Item>

                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}>

                        <Form.Item

                            name="_cth_colonia"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Input placeholder="Colonia" />
                        </Form.Item>

                        <Form.Item

                            name="_cth_municipio"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Input placeholder="Municipio o alcald&iacute;a" />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item

                            name="estado"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Input placeholder="Estado" />
                        </Form.Item>

                        <Form.Item

                            name="_cth_zip_code"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(20% - 8px)' }}
                        >
                            <Input placeholder="C&oacute;digo postal" />
                        </Form.Item>

                    </Form.Item>

                    <Title level={ 5 }>Categor&iacute;a</Title>
                    <Text >Si requiere que se agregue alguna categoría favor de ponerse en contacto con ADXpace.</Text><br/>&nbsp;<br/>

                    <Form.Item>

                        <Form.Item
                            label="Supercategor&iacute;a"
                            name="supercategoria"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Select { ...supercategoria.selectProps } onChange={ val => setSupercategory(val) } />
                        </Form.Item>

                        {

                            categoria.selectProps.options.length > 0 &&

                                <Form.Item
                                label="Categor&iacute;a"
                                name="categoria"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...categoria.selectProps } onChange={ val => setCategory(val) } />
                            </Form.Item>

                        }

                        {

                            subcategoria.selectProps.options.length > 0 &&

                            <Form.Item
                                label="Subcategor&iacute;a"
                                name="subcategoria"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...subcategoria.selectProps } onChange={ val => setSubcategory(val) } />
                            </Form.Item>

                        }

                        {

                            subcategoria1.selectProps.options.length > 0 &&

                            <Form.Item
                                label="Subcategor&iacute;a A"
                                name="subcategoria1"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...subcategoria1.selectProps } onChange={ val => setSubcategory1(val) } />
                            </Form.Item>

                        }


                        {

                            subcategoria2.selectProps.options.length > 0 &&

                            <Form.Item
                                label="Subcategor&iacute;a B"
                                name="subcategoria2"
                                rules={ [ { required: true } ] }
                                style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                            >
                                <Select { ...subcategoria2.selectProps } />
                            </Form.Item>

                        }



                    </Form.Item>


                    <Title level={ 5 } className="add-item-required">Geolocalizaci&oacute;n (M&iacute;nimo 5 decimales).</Title>

                    <Form.Item>

                        <Form.Item
                            name="_cth_latitude"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="Latitud" />
                        </Form.Item>

                        <Form.Item

                            name="_cth_longitude"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input placeholder="Longitud" />
                        </Form.Item>

                    </Form.Item>

                    <Form.Item
                        label="Precio mensual (MN)"
                        name="_cth_price"
                        rules={ [ { required: true } ] }
                        style={{ display: 'inline-block', width: 'calc(25% - 8px)', marginRight: '4px' }}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item>



                        <Form.Item
                            label="Per&iacute;odo m&iacute;nimo de contrataci&oacute;n (en d&iacute;as):"
                            name="_cth_periodo_contratación"
                            rules={ [ { required: true } ] }
                            style={{ display: 'inline-block', width: 'calc(49% - 8px)', marginRight: '4px' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Per&iacute;odo de pago (en d&iacute;as):"
                            name="_cth_periodo_pago"
                            style={{ display: 'inline-block', width: 'calc(49% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Ofrece descuento por volumen:"
                            name="_cth_descuento_volumen"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>

                    </Form.Item>




                    <Title level={ 4 }>Caracter&iacute;sticas</Title>
                    <Text level={ 5 }>(Recuerda que mientras m&aacute;s informaci&oacute;n proporciones, tu espacio ser&aacute; m&aacute;s atractivo). <br/>&nbsp; <br/></Text>


                    <Form.Item>

                        <Form.Item
                            label="Orientaci&oacute;n"
                            name="_cth_orientacion"

                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Select { ...orientaciones.selectProps } onChange={ (v) => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Orientacion');
                                    setCurrentEditingResorce(orientaciones);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Vista"
                            name="_cth_vista"

                            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                        >
                            <Select { ...vistas.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Vista');
                                    setCurrentEditingResorce(vistas);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>


                    <Title level={ 5 }>Dimensiones</Title>

                    <Form.Item>
                        <Form.Item
                            label="Base (en metros):"
                            name="_cth_base"
                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber  />
                        </Form.Item>

                        <Form.Item
                            label="Altura (en metros):"
                            name="_cth_altura"
                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber placeholder="" />
                        </Form.Item>

                        <Form.Item
                            label="Estructura:"
                            name="_cth_estructura"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <Select { ...estructura.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Estructura');
                                    setCurrentEditingResorce(estructura);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>

                    <Form.Item>
                        <Form.Item
                            label="Tama&ntilde;o de pantalla (pulgadas):"
                            name="_cth_tamano_pantalla"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber placeholder="" />
                        </Form.Item>

                        <Form.Item
                            label="Pixeles:"
                            name="pixeles"
                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                    </Form.Item>





                    <Form.Item>

                        <Form.Item
                            label="Nivel socioecon&oacute;mico zona:"
                            name="_cth_nivel_socioeconomico_zona"
                            style={{ display: 'inline-block', width: 'calc(49% - 8px)', marginRight: '4px' }}
                        >
                            <Select { ...nivel_socioeconomico_zona.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Nivel socioeconomico zona');
                                    setCurrentEditingResorce(nivel_socioeconomico_zona);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Nivel socioecon&oacute;mico audiencia:"
                            name="_cth_nivel_socioeconomico_audiencia"

                            style={{ display: 'inline-block', width: 'calc(49% - 8px)' }}
                        >
                            <Select { ...nivel_socioeconomico_audiencia.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Nivel socioeconomico audiencia');
                                    setCurrentEditingResorce(nivel_socioeconomico_audiencia);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Tipo de establecimiento:"
                            name="_cth_tipo_establecimiento"

                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '4px' }}
                        >
                            <Select { ...tipo_establecimiento.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Tipo de establecimiento');
                                    setCurrentEditingResorce(tipo_establecimiento);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="N&uacute;mero de muebles:"
                            name="_cth_numero_muebles"

                            style={{ display: 'inline-block', width: 'calc(49% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="N&uacute;mero de caras:"
                            name="_cth_numero_caras"

                            style={{ display: 'inline-block', width: 'calc(49% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Tr&aacute;fico vehicular:"
                            name="_cth_trafico_vehicular"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)', marginRight: '4px' }}
                        >
                            <Select { ...trafico_vehicular.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Trafico vehicular');
                                    setCurrentEditingResorce(trafico_vehicular);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Audiencia diaria:"
                            name="_cth_audiencia_diaria"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Afluencia anual:"
                            name="_cth_afluencia_anual"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <InputNumber />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Clave IBOPE:"
                            name="_cth_clave_ibope"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="IBOPE GLP:"
                            name="_cth_ibope_glp"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="IBOPE SVS:"
                            name="_cth_ibope_svs"

                            style={{ display: 'inline-block', width: 'calc(30% - 8px)' }}
                        >
                            <Input />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item>

                        <Form.Item
                            label="Vialidad:"
                            name="_cth_vialidad"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <Select { ...vialidad.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Vialidad');
                                    setCurrentEditingResorce(vialidad);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Visibilidad:"
                            name="_cth_visibilidad"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <Select { ...visibilidad.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Visibilidad');
                                    setCurrentEditingResorce(visibilidad);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Calificacion:"
                            name="_cth_calificacion"

                            style={{ display: 'inline-block', width: 'calc(32% - 8px)' }}
                        >
                            <Select { ...calificacion.selectProps } onChange={ v => {

                                if(v === ' -- Agregar otro valor'){

                                    setCurrentEditingCatalog('Calificacion');
                                    setCurrentEditingResorce(calificacion);
                                    setIsModalVisible(true);

                                }

                            }} />
                        </Form.Item>

                    </Form.Item>


                    <Form.Item
                        label="Observaciones"
                        name="_cth_observaciones"

                        style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                    >
                        <Input.TextArea rows={ 4 } />
                    </Form.Item>


                </AntdLayout.Content>

            </AntdLayout>

        )

    }


    return (

        <>

            <Edit actionButtons={<SaveButton { ...saveButtonProps } shape="round" size="large" style={{ background: '#0E253F', color: '#dddddd', alignSelf: 'center middle' }}>Guardar</SaveButton>}  title="Editando detalle de espacio">

                <Form {  ...formProps }
                    initialValues={{

                    }}
                    labelAlign="left"
                    labelWrap
                    onFinishFailed={(e) => {
                        open?.({
                            type: "error",
                            message: `Hay campos que son necesarios para poder modificar su espacio. Por favor revise su formulario y sus imágenes.`,
                            description: "No se pudo guardar su espacio.",
                            key: e.event?.timestamp,
                        });}}
                >

                    <br/>

                    {
                        _renderEspacioPublicitario()
                    }

                </Form>

            </Edit>

            <Modal
                bodyStyle={{ padding: '0' }}
                footer={ null }
                onCancel={ () => hideModal(false) }
                title="Agregar valor a cat&aacute;logo"
                visible={ isModalVisible }
            >
                <AddToCatalogs catalogName={ currentEditingCatalog } hideModal={ hideModal } />
            </Modal>

        </>

    )

}
