import { useEffect, useState } from 'react';

import { useShow } from "@pankod/refine-core";

import { Checkbox, Col, Descriptions, Image, Row, Show, Typography } from "@pankod/refine-antd";

import { getUserIdentity } from '../app/authProvider';

import './styles.css';

const { Title, Text } = Typography;




function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


export const SpacesShow = () => {

	const [ id_adxpace, setIdAdxpace ] = useState('');

    const { queryResult } = useShow();
	const { data, isLoading } = queryResult;
	const record = data?.data;


	useEffect(() => {

        if(!id_adxpace)

        getUserIdentity()
    	.then(r => {

    		if(r.id_adxpace){

    			setIdAdxpace(r.id_adxpace);

    		}

    	});

    });

    return (

		<>

		{

			id_adxpace ?

			<Show isLoading={ isLoading } title="Detalles del espacio">

	            <Title level={ 4 }>{ record?.post_title }</Title>

	            <Row>

	                <Col span={ 7 }>

	                    <Row>

	                        {
	                            record?.images.map((image, imageIndex) =>

	                                <Col className="guttered-row" key={ `spaceImage${ imageIndex }` } span={ imageIndex === 0 ? 24 : 12 }>
	                                    <Image src={ image.url } />
	                                </Col>

	                            )
	                        }

	                    </Row>

	                </Col>


	                <Col offset={ 1 } span={ 16 }>

	                    <Title level={ 5 }>Domicilio</Title>
	                    <Text>{ record?._cth_calle } { record?._cth_numero }, { record?._cth_colonia ? `${record._cth_colonia},` : '' } <br/>
	                        { record?._cth_municipio ? `${ record._cth_municipio },` : '' } { record?._cth_zip_code ? `${ record._cth_zip_code }` : '' }
	                    </Text>

	                    <br/>&nbsp; <br/>

	                    <Descriptions title="Caracter&iacute;sticas" bordered column={ 4 } labelStyle={{ fontWeight: '600' }} layout="vertical" >

	                        {
	                            record?._cth_id_adxpace && <Descriptions.Item label="Id ADXpace">{ record._cth_id_adxpace }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_codigo && <Descriptions.Item label="C&oacute;digo ADXpace">{ record._cth_codigo }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_base && <Descriptions.Item label="Base">{ record._cth_base }m</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_altura && <Descriptions.Item label="Altura">{ record._cth_altura }m</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_tamano_pantalla && <Descriptions.Item label="Tama&ntilde; de pantalla">{ record._cth_tamano_pantalla }"</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_pixeles && <Descriptions.Item label="Pixeles">{ record._cth_pixeles }</Descriptions.Item>
	                        }

							{
	                            record?._cth_orientacion && <Descriptions.Item label="Orientaci&oacute;n">{ record._cth_orientacion }</Descriptions.Item>
	                        }

							{
	                            record?._cth_vista && <Descriptions.Item label="Vista">{ record._cth_vista }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_estructura && <Descriptions.Item label="Estructura">{ record._cth_estructura }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_nivel_socioeconomico_zona && <Descriptions.Item label="Nivel socioecon&oacute;mico de la zona">{ record._cth_nivel_socioeconomico_zona }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_nivel_socioeconomico_audiencia && <Descriptions.Item label="Nivel socioecon&oacute;mico de la audiencia">{ record._cth_nivel_socioeconomico_audiencia }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_tipo_establecimiento && <Descriptions.Item label="Tipo de establecimiento">{ record._cth_tipo_establecimiento }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_numero_muebles && <Descriptions.Item label="N&uacute;mero de muebles">{ record._cth_numero_muebles }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_numero_caras && <Descriptions.Item label="N&uacute;mero de caras">{ record._cth_numero_caras }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_trafico_vehicular && <Descriptions.Item label="Tr&aacute;fico vehicular">{ record._cth_trafico_vehicular }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_audiencia_diaria && <Descriptions.Item label="Audiencia diaria">{ record._cth_audiencia_diaria }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_afluencia_anual && <Descriptions.Item label="Afluencia anual">{ record._cth_afluencia_anual }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_clave_ibope && <Descriptions.Item label="Clave IBOPE">{ record._cth_clave_ibope }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_ibope_glp && <Descriptions.Item label="IBOPE GLP">{ record._cth_ibope_glp }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_ibope_svs && <Descriptions.Item label="IBOPE SVS">{ record._cth_ibope_svs }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_vialidad && <Descriptions.Item label="Vialidad">{ record._cth_vialidad }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_visibilidad && <Descriptions.Item label="Vialidad">{ record._cth_visibilidad }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_calificacion && <Descriptions.Item label="Calificaci&oacute;n">{ record._cth_calificacion }</Descriptions.Item>
	                        }

	                        <Descriptions.Item span={ 4 }></Descriptions.Item>

	                        {
	                            record?._cth_observaciones && <Descriptions.Item label="Observaciones" span={ 3 }>{ record._cth_observaciones }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_price && <Descriptions.Item label="Precio" span={ 2 }>$ { numberFormat(record._cth_price, 0) } MXN</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_descuento_volumen && <Descriptions.Item label="Descuento por volumen"><Checkbox defaultChecked={ record._cth_descuento_volumen === 'a:1:{i:0;s:1:"y";}' } disabled /> &nbsp; { record._cth_descuento_volumen === 'a:1:{i:0;s:1:"y";}' ? 'SI' : 'NO' }</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_periodo_contratación && <Descriptions.Item label="Per&iacute;odo de contrataci&oacute;n">{ record._cth_periodo_contratación } d&iacute;as</Descriptions.Item>
	                        }

	                        {
	                            record?._cth_periodo_pago && <Descriptions.Item label="Per&iacute;odo de pago">{ record._cth_periodo_pago } d&iacute;as</Descriptions.Item>
	                        }


	                    </Descriptions>

	                </Col>

	            </Row>

	        </Show>

			:

			<div className="notice">
                <Title level={ 4 }>Para poder administrar sus espacios es necesario que ingrese su R. F. C. en la secci&oacute;n Perfil de usuario.</Title>
            </div>

		}

        </>

    )

}
